@use 'sass:math';

// dark mode helper mixin
@mixin dark-mode() {
  :root:not(.appearance__light) & {
    @media screen and (prefers-color-scheme: dark) {
      @content;
    }
  }

  // There is no way to have CSS output when there is a class OR
  // a media query - so we have to repeat ourselves here
  :root.appearance__dark & {
    @content;
  }
}

@mixin typography-truncate($lines: 2) {
  // https://chipcullen.com/truncating-type-at-more-than-one-line/
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lines;
  white-space: normal;
  -webkit-box-orient: vertical;
}

// header styles, based on
// https://www.figma.com/file/iURXad8kPrvoyY1exhaCHG/Web-%7C-PBS.org-Redesign?type=design&node-id=352-2288&mode=design&t=rkPcK2ae1N4iCkst-4
@mixin handle-color($color: null) {
  // so, if color isn't declared we don't return anything,
  // which means the body color will be used, which
  // is var(--text-color) - see _base.scss.
  // If $fog or $white are passed, we expressly
  // set this to var(--text-color) to ensure their use.
  // If the passed color is $pebble
  // we want to use var(--text-color--subdued).
  @if $color == $fog {
    color: var(--text-color);
  } @else if $color == $white {
    color: var(--text-color);
  } @else if $color == $pebble {
    color: var(--text-color--subdued);
  } @else if $color {
    color: $color;
  }
}

@mixin h1($color: null) {
  @include handle-color($color);

  font-size: clamp($f16, 1.6vw + 1em, $f30);
  font-weight: $bold;
  line-height: 1.1;
}

@mixin h2($color: null) {
  @include handle-color($color);

  font-size: clamp($f14, 1.2vw + 0.7em, $f22);
  font-weight: $bold;
  line-height: 1.25;
}

@mixin h3($color: null, $weight: $bold) {
  @include handle-color($color);

  font-size: clamp($f12, 1vw + 0.6em, $f20);
  font-weight: $weight;
  line-height: 1.25;
}

@mixin h4($color: null, $weight: $bold, $text-transform: none) {
  @include handle-color($color);

  font-size: clamp($f12, 1vw + 0.5em, $f18);
  font-weight: $weight;
  line-height: 1.25;
  text-transform: $text-transform;
}

@mixin h5($color: null) {
  @include handle-color($color);

  font-size: clamp($f12, 1vw + 0.9em, $f16);
  font-weight: $bold;
  line-height: 1.5;
}

@mixin h6($color: null) {
  @include handle-color($color);

  font-size: clamp($f12, 1.2vw + 1em, $f14);
  font-weight: $bold;
  line-height: 1.5;
}

@mixin body-copy-1($color: null) {
  @include handle-color($color);

  font-size: clamp($f14, 1vw + 0.5em, $f16);
  font-weight: $normal;
  line-height: 1.5;
}

@mixin body-copy-2($color: null) {
  @include handle-color($color);

  font-size: clamp($f12, 1.2vw + 1em, $f14);
  font-weight: $normal;
  line-height: 1.5;
}

@mixin body-copy-3($color: null) {
  @include handle-color($color);

  font-size: $f12;
  font-weight: $normal;
  line-height: 1.5;
}

@mixin over-title($color: null) {
  @include handle-color($color);

  font-size: clamp(10px, 1.5vw + 0.1em, 11px);
  font-weight: $bold;
  line-height: 1.5;
  text-transform: uppercase;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  height: inherit;

  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-block-end: math.div($height, $width) * 100%;
  }
}

@mixin fallback-gradient($color1, $color2, $color3, $middle-percent) {
  background: radial-gradient(
    ellipse at center,
    $color3 0%,
    $color2 $middle-percent,
    $color2 $middle-percent,
    $color1 100%
  );
}

@mixin clearfix() {
  &::before,
  &::after {
    content: ''; /* 1 */
    display: table; /* 2 */
  }

  &::after {
    clear: both;
  }
}

// Used on carousel arrows to maintain a certain aspect ratio across variations and breakpoints
@mixin arrow-button-ratio($width: 39px) {
  $ratio: 1; // the aspect ratio of the button height to width
  // After the rebrand launch in NOV 2019, this ratio was changed from 1.28 to 1
  // since we are using circles and not boxes behind the carousel arrows

  width: $width;
  height: ($width * $ratio);
}

// on carousels in the hero positions (eg show, video landing), we need some different styling
// declaring it as a mixin in order to maintain consistency
@mixin autoplay-carousel-arrows($top: 50px) {
  @include arrow-button-ratio(63px);

  transform: translateY(-50%);

  @include breakpoint($md) {
    @include arrow-button-ratio(78px);
    // need this slight tweak so the arrow lines up
    // for the video landing carousel
    transform: translateY(-48%);
  }

  &,
  &:hover,
  &:focus {
    border: 0;
    background: none;
  }

  svg {
    width: 24px;
    height: 40px;

    @include breakpoint($md) {
      width: 29px;
      height: 49px;
    }
  }
}

@mixin btn($color: $pbs-blue, $isolate-svg-color: false) {
  display: inline-flex;
  align-items: center;
  gap: $g12;
  margin-block-end: 0;
  padding: $g8 $g20;
  transition:
    background-color $duration ease-in,
    border-color $duration ease-in,
    color $duration ease-in;
  border: 1px solid $color;

  @include breakpoint($sm) {
    padding-inline: $g24;
    border-width: 2px;
  }
  // setting border radius to a very large value is how you acheive the
  // 'pill' shape that will adapt to changes in button size
  border-radius: 9999px;
  background: transparent;
  font-size: clamp($f12, 1.0857rem + 0.45vw, $f16);
  -webkit-font-smoothing: antialiased;
  font-weight: $bold;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  touch-action: manipulation;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:active {
    box-shadow: none;
  }

  svg {
    // nudge our icons down
    translate: 0 1px;
  }

  svg,
  path {
    width: auto;
    max-width: 20px;
    height: 15px;
    transition:
      // not sure why but linear is needed here for it to look right
      fill $duration linear,
      stroke $duration linear;

    @if $isolate-svg-color == false {
      stroke: currentcolor;
      fill: currentcolor;
    }

    &[class*='play'] {
      height: 11px;
      translate: 1px 0;
    }
  }

  &:focus-visible {
    outline: $focusstatelight;
  }
}

@mixin ghost-btn($color: $pbs-blue, $isolate-svg-color: false) {
  @if $color == $pbs-blue {
    --color: #{$pbs-blue};
    --hover-color: #{$medium-blue};
    --hover-border-background-color: #{$medium-blue};
    --disabled-color: #{$pebble};
    --disabled-border-background-color: #{$pebble};
  } @else if $color == $white {
    --color: #{$white};
    --hover-color: #{$pebble};
    --hover-border-background-color: #{$pebble};
    --disabled-color: #{$blue-gray};
    --disabled-border-background-color: #{$blue-gray};
  } @else {
    @error "using an unsupported ghost button color. please use $pbs-blue, $fog, $white";
  }

  &,
  &:visited {
    border-color: var(--color);
    background-color: transparent;
    color: var(--color);
  }

  @if $isolate-svg-color == false {
    // if ghost button contains an icon
    svg,
    path {
      stroke: var(--color);
      fill: var(--color);
    }
  }

  // we only want this on hover, not focus
  &:hover {
    border-color: var(--hover-border-background-color);
    color: var(--hover-color);

    @if $isolate-svg-color == false {
      svg,
      path {
        stroke: var(--hover-color);
        fill: var(--hover-color);
      }
    }
  }

  // special border styles for :active
  &:active {
    border-color: $light-blue;
  }

  &:disabled {
    border-color: var(--disabled-border-background-color);
    color: var(--disabled-color, #{$blue-gray});
    cursor: not-allowed;

    svg,
    path {
      stroke: var(--disabled-color, #{$blue-gray});
      fill: var(--disabled-color, #{$blue-gray});
    }
  }
}

// very similar to a PBS Blue ghost button, but has slightly
// different hover behavior. used on Shows Landing and Search
// Results "Sort By" toggle options as well as Show Detail
// season picker options at wider page widths.
@mixin ghost-toggle() {
  --color: #{$fog};
  --hover-color: #{$fog};
  --hover-border-background-color: #{$light-blue};

  &,
  &:visited {
    border-color: var(--color);
    background-color: transparent;
    color: var(--color);
  }

  // if ghost button contains an icon
  svg,
  path {
    stroke: var(--color);
    fill: var(--color);
  }

  // we only want this on hover, not focus
  &:hover {
    border-color: var(--hover-border-background-color);
    background-color: var(--hover-border-background-color);
    color: var(--hover-color);

    svg,
    path {
      stroke: var(--hover-color);
      fill: var(--hover-color);
    }
  }

  // special border styles for :active
  &:active {
    border-color: $light-blue;
  }
}

@mixin fill-btn($color: $pbs-blue) {
  @if $color == $pbs-blue {
    --color: #{$white};
    --background-border-color: #{$pbs-blue};
    --hover-color: #{$white};
    --hover-border-background-color: #{$medium-blue};
    --disabled-color: #{$blue-gray};
    --disabled-border-background-color: #{$charcoal};
  } @else if $color == $light-blue {
    --color: #{$white};
    --background-border-color: #{$light-blue};
    --hover-color: #{rgba($white, 0.7)};
    --hover-border-background-color: #{rgba($light-blue, 0.8)};
    --disabled-color: #{$blue-gray};
    --disabled-border-background-color: #{$charcoal};
  } @else if $color == $medium-blue {
    --color: #{$white};
    --background-border-color: #{$medium-blue};
    --hover-color: #{$white};
    --hover-border-background-color: #{$navy-blue};
  } @else if $color == $white {
    --color: #{$pbs-blue};
    --background-border-color: #{$white};
    --hover-border-background-color: #{$pebble};
    --hover-color: #{$medium-blue};
    --disabled-color: #{$shadow};
    --disabled-border-background-color: #{$slate};
  } @else if $color == 'white-alternate' {
    // this variant is used when the button should be white in light mode
    // but PBS Blue in dark mode
    --color: #{$pbs-blue};
    --background-border-color: #{$white};
    --hover-border-background-color: #{$medium-blue};
    --hover-color: #{$white};
  } @else if $color == $red {
    --color: #{$white};
    --background-border-color: #{$red};
    --hover-color: #{rgba($white, 0.8)};
    --hover-border-background-color: #{rgba($red, 0.8)};
    --active-border-color: #{$red-lighten-20};
    --disabled-color: #{$blue-gray};
    --disabled-border-background-color: #{$charcoal};
  } @else if $color == $yellow {
    --color: #{$midnight};
    --background-border-color: #{$yellow};
    --hover-color: #{$midnight};
    --hover-border-background-color: #{rgba($yellow, 0.8)};
    --active-border-color: #{$yellow-darken-20};
    --disabled-color: #{$blue-gray};
    --disabled-border-background-color: #{$charcoal};
  } @else {
    @error "Using an unsupported fill button color. Please use either $pbs-blue, $light-blue, $medium-blue, $white, $red, or $yellow";
  }

  &,
  &:visited {
    padding-block: 10px;
    border: 0;
    border-color: var(--background-border-color);
    background-color: var(--background-border-color);
    color: var(--color, #{$white});
  }

  path {
    fill: var(--color, #{$white});
  }

  &:hover {
    background-color: var(--hover-border-background-color);
    color: var(--hover-color, #{$white});

    svg,
    path {
      fill: var(--hover-color, #{$white});
    }
  }

  // special border styles for :active
  &:active {
    border-color: var(--active-border-color, #{$light-blue});
  }

  &:disabled {
    border-color: var(--disabled-border-background-color);
    background-color: var(--disabled-border-background-color);
    color: var(--disabled-color, #{$pebble});
    cursor: not-allowed;

    svg,
    path {
      stroke: var(--disabled-color, #{$pebble});
      fill: var(--disabled-color, #{$pebble});
    }
  }
}

// used in creating buttons to make sure we have all states covered
@mixin btn-states() {
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin icon-button() {
  --size: clamp(34px, 4vw + 0.5em, 36px);
  --color: #{$white};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  // this is a bit random, but it matches the user agent stylesheet
  // for button elements, so this produces a consistent look
  padding-inline: 6px;
  transition-property: border-color;
  transition-duration: var(--duration);
  border: 2px solid var(--color);
  border-radius: 50%;
  background: transparent;
  cursor: pointer;

  svg,
  path {
    width: 55%;
    max-width: 20px;
    height: 15px;
    max-height: 60%;
    transition-property: fill, stroke;
    transition-duration: var(--duration-half);
    stroke: var(--color);
    fill: var(--color);
  }

  svg {
    &[class*='play'] {
      max-width: 55%;
      translate: 1px 0;
    }

    &[class*='kabob-icon'] {
      width: 8px;
      translate: 0 1px;
    }
  }

  &:hover {
    --color: #{$pebble};
  }

  &:focus {
    outline: 3px solid $light-blue;
  }

  &:disabled {
    --color: #{$blue-gray};

    cursor: not-allowed;
  }
}

@mixin source-title() {
  color: var(--text-color--subdued);
  font-size: $f14;
  font-weight: $bold;
  text-transform: uppercase;
}

@mixin dark-sans-title() {
  color: var(--text-color);
  font-size: $f18;

  @include breakpoint($md) {
    font-size: $f22;
  }

  font-weight: $bold;
  line-height: $line-height;

  &:link,
  &:visited {
    color: var(--link-color);
  }
}

// handles hover states for blue SVG icons
@mixin default-icon() {
  svg,
  svg path,
  + * svg path {
    fill: var(--icon-color);
  }

  &:hover,
  &:focus,
  &:active {
    svg,
    svg path {
      fill: var(--icon-active-color);
    }
  }
}

@mixin white-icon() {
  svg,
  svg path,
  + * svg path {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active {
    svg,
    svg path {
      fill: rgba(255 255 255 / 75%);
    }
  }
}

// very basic pbs-blue link with medium-blue underline on hover
@mixin default-link() {
  &,
  &:link,
  &:visited {
    color: var(--link-color);
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--link-active-color);
  }
}

// gray link with medium-blue underline on hover
// usually used on over-title elements
@mixin subdued-link() {
  &,
  &:link,
  &:visited {
    color: var(--text-color--subdued);
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--link-active-color);
  }
}

// used on things like media hero so text has some darkness behind it. Accepts an optional z-index number value
@mixin overlay-shadow($z-index: null) {
  overflow: hidden; // without this, the :after element oddly wraps from left to right;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background: linear-gradient(
      5deg,
      rgba($midnight, 0.8) 0%,
      rgba($white, 0) 60%
    );
    // allows things to be clicked behind the gradient
    pointer-events: none;

    @if $z-index {
      z-index: $z-index;
    }
  }
}

// used to fade a solid color over the edge of an image, e.g. SVP hero.
// should be applied within a ::before or ::after pseudo-element,
// but not prescribing which here, since you might need to use both
// on elements where two sides of the image have the gradient applied.
@mixin border-gradient($side: bottom, $color: $midnight) {
  content: '';
  display: block;
  position: absolute;
  background-repeat: no-repeat;

  @if $side == bottom {
    // for bottom edge
    inset-block-end: 0;
    width: 100%;
    height: $g64;
    background: linear-gradient(to top, rgba($color, 1), rgba($color, 0));
  } @else if $side == left {
    // for left edge
    inset-inline-start: 0;
    width: $g64;
    height: 100%;
    background: linear-gradient(to right, rgba($color, 1), rgba($color, 0));
  }
}

// used to move the relevant page elements left or right when the mobile menu is invoked
@mixin mobile-menu() {
  transition: transform $duration ease-out;

  @media (prefers-reduced-motion: reduce) {
    transition-duration: 0s;
  }

  .nav_is_open & {
    transform: none;
  }

  .station_is_open & {
    transform: none;
  }
}

// Used in collections, now playing overlays, etc
@mixin centered-text-box() {
  display: flex;
  position: absolute;
  inset: 0;
  z-index: 4;
  flex-direction: column;
  justify-content: center;
  // because IE11 was having this block level element run out of the box
  width: 100%;
  margin-block: 0;
  padding: $g16;
  overflow-wrap: break-word;
  color: $white;
  font-size: $f20;
  font-weight: $bold;
  line-height: 1.1;
  text-align: center;
  text-shadow: $text-shadow;
  word-wrap: break-word;
}

// Responsive utilities

// More easily include all the states for responsive-utilities.less.
// [converter] $parent hack
@mixin responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }

  table#{$parent} {
    display: table !important;
  }

  tr#{$parent} {
    display: table-row !important;
  }

  th#{$parent},
  td#{$parent} {
    display: table-cell !important;
  }
}

// [converter] $parent hack
@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}

// radio button styles
@mixin custom-radio() {
  // visually hide default radio inputs
  [type='radio'] {
    position: absolute;
    margin: 0;
    opacity: 0;
  }

  [type='radio'] + label {
    display: inline-block;
    position: relative;
    padding-inline-start: 28px;
    font-size: $f14;
    line-height: 20px;
    cursor: pointer;
  }

  [type='radio']:checked + label {
    @include h5;

    font-size: $f14;
    font-weight: bold;
  }

  // pseudo-element radio button
  [type='radio'] + label::before {
    content: '';
    position: absolute;
    inset-block-start: 3px;
    inset-inline-start: 0;
    width: 15px;
    height: 15px;
    border: 2px solid $pebble;
    border-radius: 100%;
    background-color: $charcoal;

    @include breakpoint($sm) {
      inset-block-start: 0;
      width: 18px;
      height: 18px;
    }
  }

  [type='radio']:checked + label::before {
    border: 5px solid $light-blue;
  }

  [type='radio']:focus + label::before {
    outline: 2px solid var(--focus-color);
  }

  [type='radio']:not(:checked) + label:hover::before {
    border: none;
  }

  [type='radio'] + label::after {
    content: '';
    position: absolute;
    border-radius: 100%;
    background-color: $charcoal;
  }

  // checked styles
  [type='radio']:checked + label::after {
    inset-block-start: 5.5px;
    inset-inline-start: 2.5px;
    width: 10px;
    height: 10px;

    @include breakpoint($sm) {
      inset-block-start: 4px;
      inset-inline-start: 4px;
    }
  }

  // unchecked styles
  [type='radio']:not(:checked) + label::after {
    inset-block-start: 4.5px;
    inset-inline-start: 1.25px;
    width: 12px;
    height: 12px;

    @include breakpoint($sm) {
      inset-block-start: 2px;
      inset-inline-start: 2px;
      width: 14px;
      height: 14px;
    }
  }
}

@mixin custom-dropdown($padding-inline-end) {
  display: inline-flex;
  align-items: center;
  padding: $g8 $g20;
  appearance: none;
  transition-property: background-image, background-color, border-color, color;
  border: 1px solid $pbs-blue;

  // setting border radius to a very large value is how you acheive the
  // 'pill' shape that will adapt to changes in button size
  border-radius: 9999px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8' fill='white'%3E%3Cpath d='M7.8 7.5.6.9V.6C.6.4.7.4.8.4h14.5l.2.3-.1.2-7.2 6.6c-.2.1-.4.1-.4 0Z'/%3E%3C/svg%3E");
  background-position: calc(100% - $g16) center;
  background-repeat: no-repeat;

  // $white svg from /public/svg/down.svg
  font-size: clamp($f12, 1.0857rem + 0.45vw, $f16);
  -webkit-font-smoothing: antialiased;
  font-weight: $bold;
  line-height: 1;

  // Center aligns the selected option while keeping the
  // rest of the options left aligned otherwise there's a strange
  // padding that makes the options offset from the center
  // https://stackoverflow.com/questions/10813528/is-it-possible-to-center-text-in-select-box
  text-align-last: center;
  cursor: pointer;
  touch-action: manipulation;

  &:focus-visible {
    outline: $focusstatelight;
  }

  @include ghost-btn($white);

  @if $padding-inline-end {
    @include breakpoint($below-smmd) {
      padding-inline-end: $padding-inline-end;
    }
  }

  &:hover {
    // $pebble svg from /public/svg/down.svg
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8' fill='%2394a1b2'%3E%3Cpath d='M7.8 7.5.6.9V.6C.6.4.7.4.8.4h14.5l.2.3-.1.2-7.2 6.6c-.2.1-.4.1-.4 0Z'/%3E%3C/svg%3E%0A");
  }
}

@mixin dropdown-delay() {
  transition: visibility 100ms linear 150ms;
}

@mixin visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
  background-color: $white;
  color: $midnight;
}

@mixin player-placeholder() {
  position: relative;
  aspect-ratio: 16 / 9;
  background-color: $midnight; // this matches the iframe

  // Using an ::after pseudo element to indicate a video loading
  &::after {
    content: 'Loading Player…';
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 10%; // centering by % - 100% - 80% / 2
    width: 80%;
    transform: translateY(
      -40%
    ); // to center the text vertically (optically) without knowing height

    color: $white;
    font-size: $f20;
    font-weight: $normal;
    text-align: center;

    @include breakpoint($xxs) {
      font-size: $f30;
    }
  }
}

@mixin selection-dropdown() {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: calc(#{$g32} + #{$g8});
  margin: 0;
  padding-inline-start: $g12;
  appearance: none;
  border: 1px solid $fog;
  border-radius: $g8;
  background-color: transparent;
  background-image: url('../../public/svg/pbs-dropdown-triangle.svg');
  background-position:
    right $g12 top 50%,
    0 0;
  background-repeat: no-repeat, repeat;
  background-size:
    $g12 auto,
    100%;
  color: var(--text-color);
  font-size: $f12;
  line-height: $f22;

  @include breakpoint($sm) {
    font-size: $f14;
  }
}

// used to indicate the current page, e.g. Home or Shows
// should be applied within a ::before or ::after pseudo-element
@mixin current-page-nav-underline($color: transparent) {
  content: '';
  position: absolute;
  inset-inline: 0;
  inset-block-end: 0;
  width: 100%;
  height: $g4;
  background-color: $color;
}

// shared styling between video mezzanine links and collection thumbnail links
@mixin image-thumbnail-link($aspect-ratio: 16 / 9) {
  --border-thickness: 2px;

  @include breakpoint($sm) {
    --border-thickness: 3px;
  }

  display: block;
  position: relative;
  aspect-ratio: $aspect-ratio;
  transition-property: scale, border-radius, outline-color, opacity;
  transition-duration: var(--duration);
  outline: var(--border-thickness) solid transparent;

  @media (hover: hover) {
    &:hover,
    &:focus-within {
      border-radius: var(--border-thickness);
      outline-color: $white;
      scale: 1.03;
    }
  }

  img {
    width: 100%;
    aspect-ratio: $aspect-ratio;
    background-color: $onyx;
  }
}

@mixin horizontal-scroll-with-hidden-scrollbar() {
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox & Chrome */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

// Mixin for creating layouts when we have an ad next to other content
// Defaults to assuming a 300px wide ad.
// This will be useful for tabs on video and show pages.
@mixin content-layout-with-ad($adWidth: 300px) {
  // if the content has something following it - e.g. the Learning Media Badge
  // add a margin for spacing
  &:has(+ *) {
    margin-block-end: $g40;

    @include breakpoint($sm) {
      margin-block-end: $g48;
    }
  }

  // this is a special class that we add if there is a unit that should
  // affect the flow.
  &:has([class*='sponsorship_unit__in_flow']) {
    // RWEB-8656 - if there is a season navigator, push the ad down
    // so that it lines up with video thumbnails
    &:has(select[class*='season_navigator_select']) {
      [class*='sponsorship_unit__in_flow'] {
        margin-block-start: 66px;
      }
    }

    // we should make sure that google hasn't collapsed their ad for some reason
    &:not(:has(div[data-google-query-id][style='display: none;'])) {
      display: grid;
      grid-template-columns: 1fr $adWidth;
      gap: $g64;
    }
  }
}

@mixin video-detail-thumbnail-list() {
  display: flex;
  flex-direction: column;
  gap: $g20;

  @include breakpoint($md) {
    gap: $g28;
  }

  @include breakpoint($xl) {
    gap: $g32;
  }
}

@mixin containerminwidth($cbp: $sm) {
  @container (min-width: #{$cbp}) {
    @content;
  }
}

@mixin live-tv-dot() {
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: clamp(10px, 10vw, 16px);
    height: clamp(10px, 10vw, 16px);
    margin-inline-end: $g8;
    border-radius: 50%;
    background: $red;
  }
}

@mixin splide-arrow() {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  transition-property: background-color, opacity;
  transition-duration: var(--duration);
  transition-timing-function: ease-in;
  border: 0;
  border-radius: 50%;
  opacity: 1;
  background-color: rgba($pbs-blue, 0.7);
  backdrop-filter: blur(2px);

  // hide the arrow for touch devices, who can swipe
  @media (hover: none) {
    display: none;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: $white;
    pointer-events: none;
  }

  &:hover {
    background-color: rgba($medium-blue, 0.7);
  }

  &:focus-visible {
    outline: 3px solid $light-blue;
  }

  &[disabled] {
    opacity: 0;
    pointer-events: none;
  }
}

@mixin bg-blur($color: $slate, $opacity: 0.9, $blur: 10px) {
  background-color: rgba($color, $opacity);

  // stylelint-disable-next-line
  -webkit-backdrop-filter: blur($blur);
  backdrop-filter: blur($blur);
}

@mixin responsive-splide-slides() {
  width: clamp(9.1rem, 15.402vw + 4.171rem, 16rem);

  @include breakpoint($sm) {
    width: clamp(11rem, 14.286vw + 0.029rem, 20.6rem);
  }
}

@mixin menu-background() {
  @include bg-blur($midnight, $opacity: 0.85);

  @include breakpoint($sm) {
    background-color: transparent;
    background-image: linear-gradient(
      to bottom,
      rgba($blue-gray, 1) 5px,
      rgba($blue-gray, 0.85) 60px
    );
  }
}

// triangle pointer for dropdowns
// note the opacity is 0 here by default
// and needs to be set to 1 on hover
@mixin menu-triangle($left-inset: 6px, $bottom-inset: 0) {
  &::before {
    content: '';
    position: absolute;
    inset: auto 0 $bottom-inset $left-inset;
    width: 0;
    height: 0;
    transform: rotate(0deg);
    transition: opacity var(--duration-half) ease-in;
    border-width: 0 17px 12px;
    border-style: solid;
    border-color: transparent transparent $blue-gray;
    opacity: 0;
  }
}

// convenience mixin for the $utility-nav-bp breakpoint and above, *and* on devices that have a mouse
@mixin bp-utility-nav-mouse-only() {
  @media screen and (min-width: $utility-nav-bp) and (hover: hover) {
    @content;
  }
}

@mixin carousel-hover-dim($className) {
  @media (hover: hover) {
    &:has([class*='#{$className}']:hover) {
      [class*='#{$className}']:not(:hover) {
        transition-duration: var(--duration);
        opacity: 0.5;
        background-color: $midnight;
      }
    }
  }
}

// NOTE: when using this mixing on an element,
// a higher level element needs `container-type: inline-size;` applied to it
@mixin show-poster-grid() {
  display: grid;

  // 3 posters wide at smallest container size
  grid-template-columns: repeat(3, 1fr);
  gap: $g8;

  @container (min-width: 480px) {
    grid-template-columns: repeat(4, 1fr); // 4 posters wide
  }

  @container (min-width: 720px) {
    grid-template-columns: repeat(5, 1fr); // 5 posters wide
    gap: $g12;
  }

  @container (min-width: 1100px) {
    grid-template-columns: repeat(6, 1fr); // 6 posters wide
  }

  @container (min-width: 1440px) {
    grid-template-columns: repeat(7, 1fr); // 6 posters wide
  }

  // dimming effect - needs to work on both divs and links
  @media (hover: hover) {
    &:has(> div:hover, > a:hover) {
      > div:not(:hover),
      > a:not(:hover) {
        transition-duration: var(--duration);
        opacity: 0.5;
        background-color: $midnight;

        [class*='kabob_menu'] {
          display: none;
        }
      }
    }

    &:has(a:focus-visible, > div button:focus-visible) {
      > a:not(:focus-visible),
      > div:not(:focus-within) {
        transition-duration: var(--duration);
        opacity: 0.5;
        background-color: $midnight;

        > div[class*='kabob_menu'],
        > div[data-tippy-root] {
          display: none;
        }
      }
    }
  }
}

// NOTE: when using this mixing on an element,
// a higher level element needs `container-type: inline-size;` applied to it
@mixin video-thumbnail-grid() {
  display: grid;

  // 2 posters wide at smallest container size
  grid-template-columns: repeat(2, 1fr);
  gap: $g8;

  @container (min-width: 480px) {
    grid-template-columns: repeat(3, 1fr); // 3 posters wide
  }

  @container (min-width: 720px) {
    grid-template-columns: repeat(4, 1fr); // 4 posters wide
    gap: $g12;
  }

  @container (min-width: 1440px) {
    grid-template-columns: repeat(5, 1fr); // 5 posters wide
  }

  @media (hover: hover) {
    &:has(a[class*='video_mezzanine_link']:hover) {
      a[class*='video_mezzanine_link']:not(:hover) {
        opacity: 0.5;
      }
    }
  }
}

// Shared styles between social links and copy / embed buttons
// there is still a lot to specify per instance of a button
@mixin circle-icon-button($color: $fog) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  transition-duration: var(--duration-half);
  transition-timing-function: ease-in;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

@mixin my-list-kabob-menu() {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  z-index: 15;
  transition-property: opacity, scale;
  transition-duration: var(--duration-half);
  transition-timing-function: ease-in;
  border: 0;
  outline: none;

  &:focus,
  &:active {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid $white;
    background-color: rgba($midnight, 0.4);
  }

  svg {
    fill: $white;
    // adding this drop shadow so this icon is visible on top of
    // show posters that are light in color
    filter: drop-shadow(1px 1px 1px rgba($midnight, 0.4));
  }
}
