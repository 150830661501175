// Spacing variables;
$g4: 4px;
$g8: 8px;
$g12: 12px;
$g16: 16px;
$g20: 20px;
$g24: 24px;
$g28: 28px;
$g32: 32px;
$g36: 36px;
$g40: 40px;
$g44: 44px;
$g48: 48px;
$g52: 52px;
$g56: 56px;
$g60: 60px;
$g64: 64px;
$g68: 68px;
$g72: 72px;
$g76: 76px;
$g80: 80px;
$g84: 84px;
$g88: 88px;
$g92: 92px;
$g96: 96px;
$g112: 112px;
$g128: 128px;
$g144: 144px;
$g160: 160px;
$g200: 200px;

// breakpoint variables
$xxxxs: 320px;
$xxxs: 380px;
$xxs: 480px;
$xs: 600px;
$sm: 768px;
$smmd: 875px;
$md: 1024px;
$mdlg: 1140px;
$lg: 1280px;
$xl: 1440px;
$xxl: 1700px;

// "special" breakpoints: contain styles within lower/upper bounds
$below-xxxs: (max-width $xxxs - 1);
$below-xxs: (max-width $xxs - 1);
$below-xs: (max-width $xs - 1);
$below-sm: (max-width $sm - 1);
$below-smmd: (max-width $smmd - 1);
$below-md: (max-width $md - 1);
$below-mdlg: (max-width $mdlg - 1);
$below-lg: (max-width $lg - 1);
$below-xl: (max-width $xl - 1);
$xs-only: (min-width $xs) (max-width $sm - 1);
$sm-only: (min-width $sm) (max-width $md - 1);
$sm-md-only: (min-width $sm) (max-width $lg - 1);
$md-only: (min-width $md) (max-width $lg - 1);
$md-xxl-only: (min-width $md) (max-width $xxl - 1);
$max-width: $xxl; // used sometimes

// page header
$navigation-height: 43px;
$navigation-height-sm: 52px;
$navigation-height-md: 64px;
$nav-border-width: $g8;
$utility-nav-bp: $md;
$configurable-badge-bp: $sm;

// Shadows used in various places
$text-shadow: 0 2px 35px -10px rgba($slate, 0.41);
$box-shadow: 0 2px 35px -10px rgba($blue-gray, 0.48);
$box-shadow-dark-mode: 0 2px 35px -10px $onyx;
$box-shadow-dark-mode-scrolled: 0 2px 35px 2px $onyx;
$floating-player-shadow-light-mode: drop-shadow(
  0 0.5px 35px rgba(96 115 138 / 25%)
);
$floating-player-shadow-dark-mode: drop-shadow(0 0.5px 35px rgba(4 6 19 / 75%));
$slate-border: 1px solid rgba($slate, 0.5);

// Blur Radius
$blur-radius-med: 10px;
$blur-radius-lg: 15px;

// Font/text base size
// this equates to 10px
$font-size-base: 0.625rem;

// line heights
$line-height: 1.3333;

// variables used in font weight declarations
$normal: 400;
$medium: 500;
$bold: 700;

// Font size variables
// We go by even steps between 10 and 50, with the addition of 13 & 15
$f10: 1rem;
$f12: 1.2rem;
$f13: 1.3rem;
$f14: 1.4rem;
$f15: 1.5rem;
$f16: 1.6rem;
$f18: 1.8rem;
$f20: 2rem;
$f22: 2.2rem;
$f24: 2.4rem;
$f26: 2.6rem;
$f28: 2.8rem;
$f30: 3rem;
$f32: 3.2rem;
$f34: 3.4rem;
$f36: 3.6rem;
$f38: 3.8rem;
$f40: 4rem;
$f42: 4.2rem;
$f44: 4.4rem;
$f46: 4.6rem;
$f48: 4.8rem;
$f50: 5rem;

// Font Stack
$sans-serif: 'PBS Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

// for border radius usage:
$radius: 2px;
$radius-med: 5px;
$radius-ml: 8px;
$radius-large: 10px;

// for animations and transitions
$duration: 0.3s;

// for thin dividers
$divider: 1px solid $divider-color;

// old declarations of breakpoints - will merge with the others soon
$screen: 'all';

// focus state
$focusstate: 3px solid $pbs-blue;
$focusstatelight: 3px solid $light-blue;
$video-ratio: 56.25%; // 16:9
$grid-ratio-width: 16;
$grid-ratio-height: 8.95;

// Spacing and style variables ported over from _bootstrap-variables.scss
$grid-columns: 12;
$table-bg: transparent;
$table-cell-padding: 8px !default;
$table-border-color: $pebble;
$table-bg-accent: $fog;
